import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import { createUrlTreeFromSnapshot } from '@angular/router';
import { isErrorDTO } from '@pi/pi-common/src/ui-dtos/error.dtos';
import { RecordKeeperConfigList } from '@pi/pi-configuration/src/configuration/record-keepers/list';
import type { RecordKeeperConfig } from '@pi/pi-configuration/src/interfaces/record-keepers/record-keeper-config';

import { DivisionService } from '../lib/services/division.service';
import { PlanSponsorService } from '../lib/services/plan-sponsor.service';
import { UserService } from '../lib/services/user.service';

async function getBillingRedirectUrl(
    recordkeeperSegment: string | null,
    planSponsorService: PlanSponsorService,
    divisionService: DivisionService
) {
    const recordkeeperUrlSegment = recordkeeperSegment ?? 'payrollintegrations';
    const plans = await planSponsorService.getPaginatedPlanSponsors({ rows: 2 });
    // If user has access to multiple plans or no plans, go to the plan dashboard
    if (isErrorDTO(plans) || plans.data.length > 1 || plans.data.length === 0) {
        return `/${recordkeeperUrlSegment}/plan-sponsors`;
    }
    const plan = plans.data[0];
    const divisions = await divisionService.getPaginatedDivisions({ rows: 2 }, plan.id);
    // If user has access to multiple divisions or no divisions, go to the division dashboard
    if (isErrorDTO(divisions) || divisions.data.length > 1 || divisions.data.length === 0) {
        return `/${recordkeeperUrlSegment}/plan-sponsor/${plan.id}/divisions`;
    }
    // Otherwise, redirect to the billing settings for this specific division
    const division = divisions.data[0];
    return `/${recordkeeperUrlSegment}/plan-sponsor/${plan.id}/divisions/${division.id}/settings/billing`;
}

export const manageBillingGuard: CanActivateFn = async (route) => {
    const userService = inject(UserService);
    const planSponsorService = inject(PlanSponsorService);
    const divisionService = inject(DivisionService);
    const email = route.queryParamMap.get('email');
    const recordkeeper = route.queryParamMap.get('recordkeeper');
    let config: RecordKeeperConfig | undefined;
    if (recordkeeper) {
        config = RecordKeeperConfigList.find(
            (rkc) =>
                rkc.identifier.toLowerCase() === recordkeeper.toLowerCase() ||
                rkc.uiConfig.slugOverride?.toLowerCase() === recordkeeper.toLowerCase()
        );
    }
    let recordkeeperSegment = null;
    if (config) {
        recordkeeperSegment = config.uiConfig.slugOverride ?? config.identifier;
    }
    // Check if the user is already logged in
    let authResult: boolean = false;
    try {
        authResult = Boolean(await userService.getMe(false, false));
    } catch (err) {
        authResult = false;
    }
    // If logged in, figure out which division to send the user to
    if (authResult === true) {
        const url = await getBillingRedirectUrl(recordkeeperSegment, planSponsorService, divisionService);
        return createUrlTreeFromSnapshot(route, [url]);
    }

    // If no email is provided, direct to login
    if (!email) {
        return createUrlTreeFromSnapshot(route, ['/login'], { redirect: '/redirect/manage-billing' });
    }

    const redirectParams = new URLSearchParams({ email });
    if (recordkeeperSegment) {
        redirectParams.set('recordkeeper', recordkeeperSegment);
    }
    const redirect = `/redirect/manage-billing?${redirectParams.toString()}`;

    // Check if the user has a password set
    const passwordSet = await userService.checkPasswordSet(email);
    // If the user does not have a password set, go to registration if possible
    if (!passwordSet && recordkeeperSegment) {
        return createUrlTreeFromSnapshot(route, [`/${recordkeeperSegment}/create-account`], {
            redirect,
            email
        });
    }
    // If we don't know the user's recordkeeper, go to forgot password
    else if (!passwordSet) {
        return createUrlTreeFromSnapshot(route, ['/payrollintegrations/forgot-password'], {
            redirect,
            email
        });
    }
    // If we do know the user's recordkeeper, go to login for that recordkeeper
    else if (recordkeeperSegment) {
        return createUrlTreeFromSnapshot(route, [`/${recordkeeperSegment}/login`], {
            redirect,
            email
        });
    }

    // Otherwise, go to login
    return createUrlTreeFromSnapshot(route, ['/login'], { redirect, email });
};
